<template>
  <div class="px-5">
    <Form
      title="Editar noticia"
      ref="noticeForm"
      :deletable="false"
      :loading.sync="loading"
      @save="save"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    stripHtml(html) {
      // Create a new div element
      var temporalDivElement = document.createElement("div")
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = html
      // Retrieve the text property of the element (cross-browser support)
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || ""
      )
    },
    save() {
      let form = this.$refs.noticeForm.form
      this.loading = true

      this.$store
        .dispatch("global/update", {
          payload: {
            id: form.id,
            id_reporter: 1,
            id_notice_category: form.id_notice_category,
            title: form.title,
            body: form.body,
            body_only_text: this.stripHtml(form.body),
            description: form.description,
            body_short: this.stripHtml(form.body).substring(0, 250),
            url_picture: form.url_picture
          },
          route: "/notice/update",
          module: "notice"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-update", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/noticias")
          }
        })
        .catch((error) => {
          this.loading = false
          console.log("error", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/notice",
          id: this.$route.params.id
        })
        .then((response) => {
          this.$refs.noticeForm.form = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
